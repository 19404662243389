<template>
  <div>
    <add-income></add-income>
  </div>
</template>

<script>
import AddIncome from './AddSaleOrder.vue';

export default {
  components: {
    AddIncome,
  },

  data() {
    return {};
  },
};
</script>
